import "mdbootstrap/css/bootstrap.css";
import "mdbootstrap/css/mdb.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";

import React from "react";
import ReactDOM from "react-dom";
import packageJson from "../package.json";

import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sentryDsn, sentryEnvironment } from "./utils/env.json";


import "./assets/fonts/roboto/Roboto-Regular.ttf";
import "./assets/fonts/roboto/Roboto-Bold.ttf";
import "./assets/fonts/roboto/Roboto-Medium.ttf";

window.$progessPercentage = "0%";
window.$progressStatus = "20%";
window.$step = 0;

Sentry.init({
  dsn: sentryDsn,
  release: `shopezz@${packageJson.version}`,
  environment: sentryEnvironment,
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
