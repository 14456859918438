import React from "react";
import Button from "../../components/button/button";
import "./home.scss";
import ShopezLogo from "../../assets/images/landing/Logo-shopez.png";
import ShopezLogoFooter from "../../assets/images/shopez_logo.png"
import { useHistory } from "react-router-dom";
import requestImg from "../../assets/images/landing/request.png";
import fillAFormImg from "../../assets/images/landing/fill-a-form.png";
import getApprovedImg from "../../assets/images/landing/get-approved.png";
import earnImg from "../../assets/images/landing/earn.png";

const Home = () => {
    const history = useHistory();

    const login = async () => {
        history.push("/login");
    };
    const createstore = async () => {
        history.push("/createstore");
    };

    return (
        <div>
            <div className="row">
                <div className="col-12" >
                    <div className="banner-image-home" >
                        <div className="row" >
                            <div className="col-4">
                                <div className="shopez-logo mt-5">
                                    <img src={ShopezLogo} />
                                </div>
                            </div>
                            <div className="col-8 d-flex justify-content-end">
                                <div className="row header-partner mr-5 mt-5">
                                    {/* <div className="text d-flex align-items-center mr-2">Became a Partner</div> */}
                                    <div className="mr-5" onClick={() => createstore()} style={{ cursor: "pointer", fontWeight: 600, color: "#6D6E71" }}>SIGN UP</div>
                                    <div onClick={() => login()} style={{ cursor: "pointer", fontWeight: 600, color: "#6D6E71" }}>LOGIN</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-2" >
                                <div className="img-title">Rev up your business with the power of Digital Innovation</div>
                                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-11 col-xxl-12 muiDescripationHomeInfo" style={{ padding: '0' }}>Extend Your Customer Reach, Streamline Order Processing, Effortlessly Track Inventory and Propel Your Business Growth</div>
                                {/* <div className="muiDescripationHomeStoreInfo mt-2">Launch Your <span style={{ color: "#d6740b" }}>Shopez</span> Online Store in Minutes !</div> */}
                                <div className="get-button" onClick={() => createstore()}>GET STARTED</div>
                            </div>

                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

            <div className="container-fluid mb-5 mt-5">
                <div className="row mb-5">
                    <div className="col-sm-12 col-md-10 offset-md-1">
                        <h2 className="text-center text-step">Steps to Partner With Shopez</h2>
                    </div>
                </div>

                <div className="row img-des">
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={requestImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">1</div>
                                <div className="main-heading ml-2">Express Your Interest</div>
                            </div>
                            <div className="main-description">Share essential store details, including your store name, address and contact information. Upon review, you'll receive a link to input comprehensive store information for further validation.</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={fillAFormImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">2</div>
                                <div className="main-heading ml-2">Complete Business Information</div>
                            </div>
                            <div className="main-description">Enter your store categories, products, financial details, etc. Additionally, provide business credentials to validate your identity and safeguard your business name from potential misuse.</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={getApprovedImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">3</div>
                                <div className="main-heading ml-2">Stock Up Your Online Store</div>
                            </div>
                            <div className="main-description">Once approved, upload your store products, manage inventory, run periodic promotions, and much more to captivate your existing and prospective customers.</div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                                <img className="" src={earnImg} />
                                <div className="step-info mt-4">

                                </div>
                            </div> */}
                    <div className="col-sm-12 col-md-6 col-xl-3 col-3">
                        <img className="" src={earnImg} />
                        <div className="step-info mt-4">
                            <div className="d-flex">
                                <div className="main-round">4</div>
                                <div className="main-heading ml-2">Manage Order and Track Performance</div>
                            </div>
                            <div className="main-description">Seamlessly handle online orders while tracking essential store metrics such as daily sales, transactions, and net profits. Stay informed to make strategic decisions for optimizing your business performance.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-last pb-3 pl-5">
                <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-sm-12 col-md-8 col-lg-8">
                        <div className="ml-4 d-flex align-items-center" style={{ paddingTop: "10px" }}>
                            <img width="115" src={ShopezLogoFooter} />
                        </div>
                        <div className="muiDescripationFooterInfo ml-4 mt-3">An AI-powered platform for businesses to attract new customers, boost sales and maximize profit</div>
                        <div className="d-flex mt-5">
                            <div className="muiDescripationFooterInfoContact ml-4">Contact Us:</div>
                            <div className="muiDescripationFooterInfoContact ml-1"> support@myshopez.com</div>
                            <div className="muiDescripationFooterInfoContact ml-1">|</div>
                            <div className="muiDescripationFooterInfoContact ml-1">Toll-free: 1(833) ESHOPEZ</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="row ml-2 muiDescriptionFooterHeading">
                            <div className="col-3">Explore</div>
                            <div className="col-3">Social</div>
                            <div className="col-4">Legal</div>
                        </div>
                        <div className="row ml-2 mt-2 muiDescripationFooterSubHeading">
                            <div className="col-3">Home</div>
                            <div className="col-3">Instagram</div>
                            <div className="col-5">
                                <a
                                    href={"https://shopeez-back.theclientdemos.com/api/v1/user/merchant/privacy/policy/"}
                                    download={"https://shopeez-back.theclientdemos.com/api/v1/user/merchant/privacy/policy/"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="muiDescripationFooterSubHeading"
                                    style={{ textDecoration: "none", borderBottom: "none" }}
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className="row ml-2 mt-2 muiDescripationFooterSubHeading">
                            <div className="col-3">Sign Up</div>
                            <div className="col-3">Twitter</div>
                            <div className="col-5">
                            <a
                                    href={"https://shopeez-back.theclientdemos.com/api/v1/user/merchant/terms/conditions/"}
                                    download={"https://shopeez-back.theclientdemos.com/api/v1/user/merchant/terms/conditions/"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="muiDescripationFooterSubHeading"
                                    style={{ textDecoration: "none", borderBottom: "none" }}
                                >
                                    Terms & Conditions
                                </a>
                            </div>
                        </div>
                        <div className="row ml-2 mt-2 muiDescripationFooterSubHeading">
                            <div className="col-3">Login</div>
                            <div className="col-3">Facebook</div>
                            {/* <div className="col-5">Cart</div> */}
                        </div>
                        <div className="row ml-2 mt-2 muiDescripationFooterSubHeading">
                            <div className="col-3">Zapper</div>
                            <div className="col-3">Youtube</div>
                            {/* <div className="col-5">Cart</div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;
